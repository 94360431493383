import React, { useState, useEffect, useContext, useRef } from "react";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

import DataContext from "../../../utils/Context/DataContext";
import { AntDesign, Entypo, Feather } from "@expo/vector-icons";

import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { api } from "../../../utils/Api";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome,
} from "@expo/vector-icons";

import { makeStyles } from "@material-ui/core/styles";
import ModalOrderTracking from "./ModalOrderTracking";
import InfiniteScroll from "react-infinite-scroll-component";
import { format, render, cancel, register } from "timeago.js";

import ModalOrderConfirmed from "./ModalOrderConfirmed";
import ModalChooseItems from "./ModalChooseItems";
import ModalPackageDetails from "./ModalPackageDetails";
import ModalEditPackageDetails from "./ModalEditPackageDetails";
import ModalEditItems from "./ModalEditItems";
import ModalPrepareTime from "./ModalPrepareTime";
import ModalDeleteShipment from "./ModalDeleteShipment";
import ModalRejected from "./ModalRejected";
// import ModalLocationLive from "./ModalLocationLive";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";
import { mobileApp } from "../../../utils/MobileApp";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { AppConfig } from "../../../config";
import classNames from "classnames";
import ProductList from "./ProductList";
import OrderChat from "../../../components/OrderChat/OrderChat";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function OrdersList(props: any) {
  // console.log(props);
  const navigate = useNavigate();

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // const anchorRef = React.useRef(null);
  const context: any = useContext(DataContext);
  const [grid_view, SetGridView] = useState(false);
  const [view_type, SetViewType]: any = useState(props?.view);
  const [list_view, SetListView] = useState(true);
  const [id_value, SetIdValue] = useState(useParams());
  const [order_data, SetOrderData] = useState([]);
  const [products_diet_type, SetProductsDietType]: any = useState({});
  const [data, SetData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [close, SetClose] = useState(false);
  const [prop_data, SetPropData] = useState(props);
  const [next_page, SetNextPage] = useState(false);
  const [page_no, SetPageNo] = useState(1);
  const [visible_content, SetVisibleContent] = useState(true);
  const [counterPrep, setCounterPrep]: any = useState({});
  const [is_loading, SetIsLoading] = useState(false);

  const [order_tracking_modal, SetOrderTrackingModal] = useState({
    is_open: false,
    lat: "",
    lon: "",
    id: "",
    delivery_id: "",
    delivery_index: "",
    order_data: {},
    brand_id: "",
    outlet_id: "",
  });

  const timerProps = {
    isPlaying: true,
    size: 50,
    strokeWidth: 4,
  };

  const renderTime = (dimension: any, time: any) => {
    return (
      <div className="time-wrapper text-black">
        <div className="time center">{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };
  const [openModal, SetopenModal] = useState({
    is_open: false,
    data: {},
  });
  const [prepTimeModal, SetPrepTimeModal]: any = useState({
    is_open: false,

    brand: id_value.brand_id,
    outlet: id_value.outlet_id,
    approx_time: "",
    order_id: "",
  });
  const [chooseItemModal, SetChooseItemModal] = useState({
    is_open: false,
    data: {},
    id: "",
  });

  const [createPackageModal, SetCreatePackageModal] = useState({
    is_open: false,
    data: {},
    id: "",
  });

  const [editPackageModal, SetEditPackageModal] = useState({
    is_open: false,
    data: {},
    id: "",
  });

  const [editItemModal, SetEditItemModal] = useState({
    is_open: false,
    data: {},
    id: "",
  });

  const [deleteShipModal, SetDeleteShipModal] = useState({
    is_open: false,
    data: {},
  });

  const [rejectModal, SetRejectModal] = useState({
    is_open: false,
    data: {},
  });

  const currentURL = window.location.href;
  const minuteSeconds = 60;
  const hourSeconds = 3600;

  const getTimeMinutes = (time: any) =>
    ((time % hourSeconds) / minuteSeconds) | 0;
  const getTimeSeconds = (time: any) => (minuteSeconds - time) | 0;

  const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = stratTime + 243248; // use UNIX timestamp in seconds

  const remainingTime = endTime - stratTime;
  const [mapdriverModal, SetMapDriverModal] = useState({
    is_open: false,
    lat: "",
    lon: "",
    id: "",
    brand_id: "",
    outlet_id: "",
  });
  const [new_orders_available, SetNewOrdersAvailable] = useState(false);
  const [tost_id, SetTostId] = useState([]);
  let orders_call_interval: any;
  const [chatModal, SetChatModal]: any = useState({
    is_open: false,
    id: "",
    data: {},
  });

  useEffect(() => {
    console.log(" {ele.seller_instructions: ", order_data);
    // SetOrderData(props.data.orders);
    // orders_call_interval = setInterval(function () {
    //   SetOrderData((prevalue) => {
    //     console.log("order_data in setInterval prevalue:", prevalue);
    //     let order_data: any = [];
    //     prevalue.map((ele: any) => {
    //       order_data.push(ele.id);
    //     });
    //     let send_data = {
    //       orders: order_data,
    //     };
    //     get_timed_orders(send_data);
    //     return [...prevalue];
    //   });
    // }, 20000);
    // call_timed_function();
    return () => {
      clearInterval(orders_call_interval);
      props.on_new_order(false);
    };
  }, []);

  useEffect(() => {
    // if(context.app_data.pages.hasOwnProperty('10')){
    //   if(context.app_data.pages[10]){
    console.log(props);
    if (props.view) {
      window.scrollTo(0, 0);
      props.on_new_order(false);
      SetPageNo(1);
      SetViewType(props.view);

      get_order(props.view);

      // console.log("useEffect On id_value change  :", props);
      // SetIdValue((prevalue: any) => {
      //   console.log("SetIdValue prevalue :", prevalue);
      //   return prevalue;
      // });
      // SetOrderData(props.data.orders);
      // if(props?.data?.ordered_product_spec){
      //   SetProductsDietType((prevValue:any) => {
      //     return {...prevValue, ...props?.data?.ordered_product_spec};
      //   });
      // }

      // SetNextPage(props.data.next_page);

      clearInterval(orders_call_interval);
      call_timed_function();
    } else {
      clearInterval(orders_call_interval);
      props.on_new_order(false);
    }
    return () => {
      clearInterval(orders_call_interval);
      props.on_new_order(false);
    };
    // }else{
    //   console.log("hi")
    //   navigate("/no_access");

    // }}else{
    //   SetIsLoading(true)
    // }
  }, [
    // props.view,context.app_data.pages?.[10]
    props.view,
  ]);
  useEffect(() => {
    if (order_data) {
      order_data?.map((ele: any, i_item: any) => {
        let minutes: any = 0;
        let sec_value = ele?.approx_preparation_duration;
        if (sec_value) {
          minutes = Math.floor(sec_value / 60);
        }
        if (!counterPrep.hasOwnProperty("id_" + ele.id)) {
          setCounterPrep((prev: any) => {
            prev["id_" + ele.id] = minutes;
            return { ...prev };
          });
        }
      });
    }
  }, [order_data]);

  function call_timed_function() {
    orders_call_interval = setInterval(function () {
      SetOrderData((prevalue) => {
        console.log("order_data in setInterval prevalue:", prevalue);
        let order_data: any = [];
        prevalue?.map((ele: any) => {
          order_data.push(ele.id);
        });
        // let new_sample_value: any = useParams();
        let send_data = {
          orders: order_data,
          type: props?.view,
        };
        get_timed_orders(send_data);
        return prevalue;
      });
    }, 20000);
  }

  async function load_more(page_value) {
    let page_no_value = page_no + 1;

    SetPageNo(page_no_value);

    console.log(id_value);

    let pass_data: any;

    if (id_value.hasOwnProperty("brand_id")) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
          view: view_type,
          page: page_no_value,
        },
      };
    } else {
      pass_data = {
        get: {
          type: "all",
          view: view_type,
          page: page_no_value,
        },
      };
    }

    let response: any = await api("/outlet/orders", pass_data);

    if (response?.status_code == 200) {
      console.log("/brand/add_brand_product_list :", response);

      SetOrderData((prevValue) => {
        response.response.orders.map((item) => {
          prevValue.push(item);
        });
        return [...prevValue];
      });
      if (response?.response?.ordered_product_spec) {
        SetProductsDietType((prevValue: any) => {
          return { ...prevValue, ...response?.response?.ordered_product_spec };
        });
      }
      if (response.response.hasOwnProperty("next_page")) {
        SetNextPage(response?.response?.next_page);
        // SetPageNo((prevalue) => {
        //   prevalue = prevalue + 1;
        //   return prevalue;
        // });
      }
    }
  }

  // const prevOpen = React.useRef(open);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);

  async function get_order(view: any) {
    // SetViewType(view);
    console.log(view);
    SetIsLoading(true);
    console.log(id_value);
    let pass_data: any;

    if (id_value.hasOwnProperty("brand_id")) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
        },
      };
    } else {
      pass_data = {
        get: {
          type: "all",
        },
      };
    }

    let response: any;
    if (id_value.hasOwnProperty("order_id")) {
      if (id_value.order_id) {
        pass_data.get.order_id = id_value.order_id;

        response = await api("/outlet/order_details", pass_data);
      }
    }

    if (view) {
      pass_data.get.view = view;
    } else if (props?.view) {
      pass_data.get.view = props?.view;
    }
    if (pass_data?.get?.view) {
      pass_data.get.page = 1;

      response = await api("/outlet/orders", pass_data);
    }

    if (response?.status_code == 200) {
      if (response.response.hasOwnProperty("orders")) {
        let orders = response.response.orders;
        orders.map((ele: any) => {
          ele.open_prepare_data = false;
        });
        SetOrderData(orders);
        console.log("orders data :", orders);
        SetData(response?.response?.options);
      }
      SetIsLoading(false);
      SetNextPage(response?.response?.next_page);
      if (response.response?.ordered_product_spec) {
        SetProductsDietType((prevValue: any) => {
          return { ...prevValue, ...response?.response?.ordered_product_spec };
        });
      }
    }
  }

  async function get_order_home(view: any) {
    // SetViewType(view);
    SetIsLoading(true);
    console.log(id_value);
    let pass_data;

    pass_data = {
      get: {
        type: "all",
        // outlet: id_value.outlet_id,
      },
    };

    let response: any;

    // if (id_value.order_id) {
    //   pass_data.get.order_id = id_value.order_id;

    //   response = await api("/outlet/order_details", pass_data);
    // }

    // if (view) {
    //   pass_data.get.view = view;
    // } else if (props?.view) {
    //   pass_data.get.view = props?.view;
    // }
    // if (pass_data?.get?.view) {
    //   pass_data.get.page = 1;

    response = await api("/outlet/orders", pass_data);
    // }

    if (response?.status_code == 200) {
      if (response.response.hasOwnProperty("orders")) {
        let orders = response.response.orders;
        orders.map((ele: any) => {
          ele.open_prepare_data = false;
        });
        SetOrderData(orders);

        SetData(response?.response?.options);
      }
      SetIsLoading(false);
      SetNextPage(response?.response?.next_page);
      if (response.response?.ordered_product_spec) {
        SetProductsDietType((prevValue: any) => {
          return { ...prevValue, ...response?.response?.ordered_product_spec };
        });
      }
    }
  }

  async function get_timed_orders(get_data: any) {
    let pass_data: any;

    if (id_value.hasOwnProperty("brand_id")) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          outlet: id_value.outlet_id,
          view: get_data.type,
          orders: encodeURI(JSON.stringify(get_data.orders)),
        },
      };
    } else {
      pass_data = {
        get: {
          type: "all",
          view: get_data.type,
          orders: encodeURI(JSON.stringify(get_data.orders)),
        },
      };
    }

    console.log("get_timed_orders pass_data :", pass_data);

    let response: any = await api("/outlet/get_orders", pass_data);
    console.log("/outlet/get_orders :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("orders")) {
        let orders = response.response.orders;

        SetOrderData((prevalue) => {
          prevalue = orders;
          return [...prevalue];
        });

        SetData(response?.response?.options);
      }
      if (response.response.new_order > 0) {
        SetNewOrdersAvailable(true);
        props.on_new_order(true);
      } else {
        SetNewOrdersAvailable(false);
        props.on_new_order(false);
      }
    }
  }

  function ModalOnClose(data: any) {
    SetopenModal({
      is_open: false,
      data: {},
    });
    if (data.action == "delete") {
      console.log("on confirm delete :", data.value);
      delete_user(data.value.element, data.value.index);
    }

    SetPrepTimeModal({
      is_open: false,

      brand: id_value.brand_id,
      outlet: id_value.outlet_id,
      approx_time: "",

      order_id: "",
    });
  }

  async function accept(order_id: any, brand_id: any, outlet_id: any) {
    let pass_data: any = {
      get: {
        brand: brand_id,
        outlet: outlet_id,
        order_id: order_id,
      },

      post: {
        status: "Accept",
        // outlet : id_value.outlet_id,
        // order_id : order_id,
      },
    };
    if (
      counterPrep.hasOwnProperty("id_" + order_id) &&
      counterPrep["id_" + order_id] >= 0
    ) {
      pass_data.post.duration = counterPrep["id_" + order_id];
    }
    console.log("remove_owner record :", order_id);

    let data: any = await api("/outlet/update_order_status", pass_data);
    console.log("/outlet/update_order_status :", data);
    if (data.status_code == 200) {
      const ring = {
        function: "refreshringnotifications",
        app_type: "react_web",
      };
      mobileApp(ring);
      get_order(props?.view);
    }
  }

  async function submit_ready(delivery_id: any) {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        delivery_id: delivery_id,
      },

      post: {
        status: "Accept",
        // outlet : id_value.outlet_id,
        // order_id : order_id,
      },
    };
    // console.log("remove_owner record :", order_id);

    let data: any = await api("/outlet/update_order_status", pass_data);
    console.log("/outlet/update_order_status :", data);
    if (data.status_code == 200) {
      get_order(props?.view);
    }
  }

  async function submit_ready_ship(r_id: any, brand_id: any, outlet_id: any) {
    // props.close();

    let pass_data = {
      get: {
        brand: brand_id,
        outlet: outlet_id,
        delivery_id: r_id,
      },
      post: {
        status: "Ready",
      },
    };

    console.log("pass_data :", pass_data);

    let data_res: any = await api("/outlet/update_delivery_status", pass_data);
    console.log("/outlet/update_delivery_status :", data_res.response);
    if (data_res.status_code == 200) {
      get_order(props?.view);
      // let item = close_data;//
      // SetStatus(true);
      // item.action = "edit";
      // item.value = data_res.response.orders[0];//
      // item.index = props.data.index;
      // console.log("Close data : ", item);
      // SetCloseData(item);
      // SetFranchiseData(franchise_initial_value);
      // myModal.hide();
      // console.log("gfrhjgf")
      // props.close();
    }
  }

  async function update_prepare(id: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: id,
      },
      post: {
        duration: counter,
        status: "Accept",
      },
    };
    console.log("pass_data :", pass_data);

    let data_res: any = await api("/outlet/update_order_status", pass_data);
    console.log("/outlet/update_delivery_package :", data_res);

    if (data_res.status_code == 200) {
      get_order(props?.view);
    }
  }

  // async function del_partner_number(id: any, del_id: any, brand_id, outlet_id) {
  //   let pass_data = {
  //     get: {
  //       brand: brand_id,
  //       outlet: outlet_id,
  //       order_id: id,
  //       callto: "delivery_partner",
  //       delivery_id: del_id,
  //     },
  //   };
  //   console.log("pass_data :", pass_data);

  //   let data_res = await api("/outlet/call", pass_data);
  //   console.log("/outlet/call :", data_res);

  //   if ((data_res.status_code = 200)) {
  //     // if (data_res?.response?.phone_number) {
  //     //   // document.location.href = 'tel:'+data_res?.response?.phone_number;
  //     //   window.open("tel:" + data_res?.response?.phone_number);
  //     // }
  //     if (data_res?.response?.phone_number) {
  //       if (context?.app_data.application?.platform == "android") {
  //         window.open("tel:" + data_res?.response?.phone_number);
  //       } else {
  //         window.location.href = "tel:" + data_res?.response?.phone_number;
  //       }
  //     }
  //     {
  //       /* <a
  //                                             href={
  //                                               "tel:" +
  //                                               ele.delivery[0]
  //                                                 .hyperlocal_pickup
  //                                                 .delivery_partner_mobile
  //                                             }
  //                                           > */
  //     }
  //     // get_order(props?.view);
  //   }
  // }

  async function InitiateCall(e: any, get_data: any) {
    e.preventDefault();
    e.stopPropagation();
    let pass_data: any = {
      get: {
        order_id: get_data.order_id,
        brand: get_data.brand_id,
        outlet: get_data.outlet_id,
        callto:
          get_data.to == "delivery_partner" ? "delivery_partner" : "customer",
      },
    };
    if (get_data.to == "delivery_partner") {
      pass_data.get.delivery_id = get_data.delivery_id;
    }
    let data_res: any = await api("/outlet/call", pass_data);
    if ((data_res.status_code = 200)) {
      if (data_res?.response?.phone_number) {
        if (context?.app_data.application?.platform == "android") {
          window.open("tel:" + data_res?.response?.phone_number);
        } else {
          window.location.href = "tel:" + data_res?.response?.phone_number;
        }
      }
    }
  }

  async function InitiateChat(e: any, get_data: any) {
    e.preventDefault();
    e.stopPropagation();
    let pass_data: any = {
      get: {
        order_id: get_data.order_id,
        chatto: get_data.to,
        brand: get_data.brand_id,
        outlet: get_data.outlet_id,
      },
    };
    if (get_data.chatto == "delivery_partner") {
      pass_data.get.delivery_id = get_data.delivery_id;
    }
    let data: any = await api("/outlet/chat", pass_data);
    if (data.status_code == 200) {
      if (data?.response?.sb_user_id && data?.response?.channel_url) {
        SetChatModal({
          is_open: true,
          data: {
            orderId: get_data.order_id,
            sb_user_id: data?.response?.sb_user_id,
            channel_url: data?.response?.channel_url,
            title: data?.response?.user_name,
            profile_url: data?.response?.profile_url,
            brand_id: get_data.brand_id,
            outlet_id: get_data.outlet_id,
          },
        });
      }
    }
    // setCallBtnLoader(false);
  }

  const counterMinus = (ele: any, e: any) => {
    e.stopPropagation();
    setCounterPrep((prev: any) => {
      if (prev["id_" + ele.id] > 0) {
        prev["id_" + ele.id]--;
      }
      return { ...prev };
    });
  };
  const counterPlus = (ele: any, e: any) => {
    e.stopPropagation();
    let minutes: any = 1000;
    let sec_value = ele?.max_preparation_duration;
    if (sec_value) {
      minutes = Math.floor(sec_value / 60);
    }
    setCounterPrep((prev: any) => {
      if (prev["id_" + ele.id] < minutes) {
        prev["id_" + ele.id]++;
      }
      return { ...prev };
    });
  };

  return (
    <div className="w-100 orders_list_page">
      <div className="">
        {is_loading ? (
          <div className="row">
            {[1, 2, 3, 4, 5, 6].map((sk_ele: any) => (
              <div className="col-lg-4 mb-3">
                <div className="card p-12">
                  <div className="d-flex">
                    <div className="w-75">
                      <div className="d-flex align-items-center">
                        <Skeleton height={12} width={80} />
                      </div>
                    </div>
                    <div className="w-25 text-end">
                      <Skeleton height={10} width={80} />
                    </div>
                  </div>

                  <div className="pt-2 d-flex align-items-center">
                    <Skeleton height={10} width={150} />
                    <div className="ms-auto">
                      <Skeleton height={10} width={80} />
                    </div>
                  </div>
                  <div className="my-2 border-bottom-light-2px"></div>

                  <div className="pt-1">
                    <div className="d-flex mb-2">
                      <div className="pe-2">
                        <Skeleton height={15} width={15} />
                      </div>
                      <div className="w-100">
                        <Skeleton containerClassName="w-100" height={10} />
                        <Skeleton width={100} height={10} />
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="pe-2">
                        <Skeleton height={15} width={15} />
                      </div>
                      <div className="w-100">
                        <Skeleton containerClassName="w-100" height={10} />
                        <Skeleton width={100} height={10} />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="pe-2">
                        <Skeleton height={15} width={15} />
                      </div>
                      <div className="w-100">
                        <Skeleton containerClassName="w-100" height={10} />
                        <Skeleton width={100} height={10} />
                      </div>
                    </div>

                    <div className="my-2 border-bottom-light-2px"></div>

                    <div className="d-flex align-items-center">
                      <Skeleton height={15} width={120} />
                      <div className="ms-auto">
                        <Skeleton height={15} width={60} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            {order_data && order_data.length > 0 ? (
              <InfiniteScroll
                className="row"
                dataLength={order_data?.length}
                next={() => {
                  let get_data = {
                    load_more: true,

                    page_no: page_no,
                  };
                  // console.log(pass_data)
                  console.log(get_data);

                  load_more(get_data);
                }}
                hasMore={next_page}
                loader={
                  <div className="d-flex align-items-center justify-content-center text-center p-5">
                    <div className="lds-dual-ring"></div>
                    <span className=" small ps-2">Just a second ...</span>
                  </div>
                }
              >
                <>
                  {order_data?.map((ele: any, i_item: any) => (
                    <>
                      {/* {ele?.delivery?.length > 0 &&
                        ele?.delivery?.map((shipment: any, shipment_i: any) => ( */}
                      <div className="mb-3 col-lg-4" key={i_item}>
                        <div className="card p-3 bg-fff">
                          <div className="d-flex align-items-center w-100 mb-1">
                            <div className="fs-10px text-dark fw-bold pe-2 ">
                              {/* ID: {shipment?.id} */}
                              ID: {ele?.id}
                            </div>
                            {ele.dm_order && (
                              <div className="d-flex align-items-center ps-2 border-left-black">
                                <div className="orange-circle"></div>
                                <span className="ps-2 text-orange">Demo</span>
                              </div>
                            )}

                            <div className="ms-auto">
                              {ele.order_status_id == 5 &&
                              ele?.delivery[0]?.remaining_preparing_duration >
                                0 ? (
                                <CountdownCircleTimer
                                  {...timerProps}
                                  colors={[
                                    "#1D741B",
                                    "#0E86D4",
                                    "#FF2511",
                                    "#fffff",
                                  ]}
                                  colorsTime={[20, 10, 5, 0]}
                                  duration={hourSeconds}
                                  initialRemainingTime={
                                    ele?.delivery[0]
                                      ?.remaining_preparing_duration %
                                    hourSeconds
                                  }
                                  onComplete={(totalElapsedTime) => ({
                                    shouldRepeat:
                                      ele?.delivery[0]
                                        ?.remaining_preparing_duration -
                                        totalElapsedTime >
                                      minuteSeconds,
                                  })}
                                >
                                  {({ elapsedTime, color }) => (
                                    <span style={{ color, lineHeight: "0.8" }}>
                                      {renderTime(
                                        "",
                                        getTimeMinutes(
                                          hourSeconds - elapsedTime
                                        )
                                      )}
                                      <span className="fs-10">mins</span>
                                    </span>
                                  )}
                                </CountdownCircleTimer>
                              ) : (
                                <div className="fs-10 text-gray">
                                  {format(ele?.timeago, "en_US")}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex align-items-center border-bottom pb-2 mb-2">
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "31b719f813a5801b0b9e68653ff6df89.svg"
                              }
                              className="img-fluid me-1"
                              width="14"
                            />
                            <div className="fs-12">Hyperlocal</div>
                            <span
                              className={
                                ele.order_status_id == 4
                                  ? "center badge-1 ms-2 badge-red-2"
                                  : ele?.delivery[0]?.status_id == 3
                                  ? "center badge-1 ms-2 badge-purple"
                                  : ele?.delivery[0]?.status_id == 4
                                  ? "center badge-1 ms-2 badge-orange"
                                  : ele?.delivery[0]?.status_id == 6
                                  ? "center badge-1 ms-2 badge-pin"
                                  : ele?.delivery[0]?.status_id == 2
                                  ? "center badge-1 ms-2 badge-red-2"
                                  : ele?.delivery[0]?.status_id == 7
                                  ? "center badge-1 ms-2 badge-green"
                                  : "center badge-1 ms-2"
                              }
                            >
                              {ele.order_status_id == 4
                                ? ele.order_status
                                : ele?.delivery[0]?.status}
                              {/* Delivered */}
                            </span>

                            <div className="ms-auto cursor">
                              <Link
                                // to={"/order_details_ui"}
                                to={
                                  "/outlet/" +
                                  ele.brand_id +
                                  "/" +
                                  ele.outlet_id +
                                  "/order_details" +
                                  "/" +
                                  ele.id
                                }
                                className="text-black"
                              >
                                <div className="fs-11 text-primary">
                                  View Details
                                </div>
                              </Link>
                            </div>
                          </div>
                          <ProductList
                            products={
                              ele?.delivery.length > 0
                                ? ele?.delivery[0]?.products
                                : ele.products
                            }
                            diet_type={products_diet_type}
                          />
                          <div className="border-top">
                            <div className="d-flex align-items-center w-100 pt-2 mb-2">
                              <div className="d-flex">
                                <div className="me-2 fs-13px text-dark fw-600">
                                  Total Bill
                                </div>
                                {ele?.cod_order == true &&
                                ele?.cod_payment_status == true ? (
                                  <span className="payment-badge">
                                    COD Paid
                                  </span>
                                ) : ele?.cod_order == true ? (
                                  <span className="payment-badge">COD</span>
                                ) : (
                                  <span className="payment-badge">Paid</span>
                                )}
                              </div>
                              <div className="ms-auto">
                                <span className=" fs-13px text-dark fw-600">
                                  &#8377;
                                </span>{" "}
                                <span className=" fs-13px text-dark fw-600">
                                  {/* {ele?.total_amount} */}
                                  {ele?.amount}
                                </span>
                              </div>
                            </div>
                          </div>

                          {ele.order_status_id == 1 && (
                            <>
                              <div className="bg-light-gray p-3 radius-10 my-2">
                                {(ele.sector_id == 2 ||
                                  ele.sector_id == 3 ||
                                  ele.sector_id == 1) &&
                                  ele.delivery_type_id == 3 && (
                                    <div>
                                      <div className="d-flex align-items-center">
                                        <div className="fs-12 mb-1 me-2">
                                          How Long will this take a time to
                                          prepare
                                        </div>
                                        <div className="custom-incrementer mb-2 bg-fff">
                                          <div
                                            className="btn"
                                            onClick={() => {
                                              setCounterPrep((prev: any) => {
                                                if (prev["id_" + ele.id] > 0) {
                                                  prev["id_" + ele.id]--;
                                                }
                                                return { ...prev };
                                              });
                                            }}
                                          >
                                            -
                                          </div>
                                          <div className="counter-number d-flex">
                                            <span>
                                              {counterPrep["id_" + ele.id]}
                                            </span>
                                            <span>
                                              {counterPrep["id_" + ele.id] > 1
                                                ? "mins"
                                                : "min"}
                                            </span>
                                          </div>
                                          <div
                                            className="btn"
                                            onClick={() => {
                                              let minutes: any = 1000;
                                              let sec_value =
                                                ele?.max_preparation_duration;
                                              if (sec_value) {
                                                minutes = Math.floor(
                                                  sec_value / 60
                                                );
                                              }
                                              setCounterPrep((prev: any) => {
                                                if (
                                                  prev["id_" + ele.id] < minutes
                                                ) {
                                                  prev["id_" + ele.id]++;
                                                }
                                                return { ...prev };
                                              });
                                            }}
                                          >
                                            +
                                          </div>
                                        </div>
                                      </div>

                                      <div className="d-flex mt-2">
                                        <div className=" ps-0 pe-1">
                                          <button
                                            className="btn btn-reject bg-fff px-4 py-2"
                                            onClick={(event) => {
                                              SetRejectModal({
                                                is_open: true,
                                                data: {
                                                  id: ele.id,
                                                  brand_id: ele.brand_id,
                                                  outlet_id: ele.outlet_id,
                                                },
                                              });
                                            }}
                                          >
                                            Reject
                                          </button>
                                        </div>
                                        <div className="ms-2 pe-0 ps-1">
                                          <button
                                            className="btn bg-success btn-accept px-4 py-2"
                                            onClick={(event) => {
                                              accept(
                                                ele.id,
                                                ele.brand_id,
                                                ele.outlet_id
                                              );
                                            }}
                                          >
                                            Accept
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </>
                          )}

                          <div className="bg-light-blue p-2 radius-10 mt-2">
                            <div className="d-flex align-items-center pb-2 border-bottom">
                              {ele.customer_dp ? (
                                <img
                                  src={ele.customer_dp}
                                  alt="16.a1d4f3f3"
                                  className="rounded-float-start"
                                  height="30"
                                  width="30"
                                  style={{
                                    borderRadius: "6px",
                                  }}
                                />
                              ) : (
                                <div className="w-30px bg-fff h-30px radius-6 center">
                                  <img
                                    src={
                                      AppConfig.CDN_Image_url +
                                      "b768514a88b9a901a98ea3bb85c71049.png"
                                    }
                                    className="w-10"
                                  />
                                </div>
                              )}
                              <div className="ps-2">
                                <div className="fs-9px pop-regular text-gray">
                                  Customer Name
                                </div>
                                <div className="pop-font text-dark fs-12">
                                  {ele.customer_name}
                                </div>
                              </div>
                              <div className="ms-auto end">
                                <div
                                  className="os-icon shadow center"
                                  onClick={(e) => {
                                    let send_data = {
                                      order_id: ele.id,
                                      to: "customer",
                                      brand_id: ele.brand_id,
                                      outlet_id: ele.outlet_id,
                                      // delivery_id: shipment.hyperlocal_pickup.delivery_partner_mobile_delivery_id,
                                    };
                                    InitiateCall(e, send_data);
                                  }}
                                >
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "a78b858bd49bf56c9fa495b641269677.svg"
                                    }
                                    className="w-16px filter-invert"
                                  />
                                </div>
                                <div
                                  className="ms-3 os-icon shadow center"
                                  onClick={(e) => {
                                    let send_data = {
                                      order_id: ele.id,
                                      to: "customer",
                                      brand_id: ele.brand_id,
                                      outlet_id: ele.outlet_id,
                                      // delivery_id: shipment.hyperlocal_pickup.delivery_partner_mobile_delivery_id,
                                    };
                                    InitiateChat(e, send_data);
                                  }}
                                >
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                                    }
                                    className="w-16px filter-invert flip-icon"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Delivery Point Cotact */}
                            <div className="d-flex align-items-center py-2 border-bottom">
                              {ele.customer_dp ? (
                                <img
                                  src={ele.customer_dp}
                                  alt="16.a1d4f3f3"
                                  className="rounded-float-start"
                                  height="30"
                                  width="30"
                                  style={{
                                    borderRadius: "6px",
                                  }}
                                />
                              ) : (
                                <div className="w-30px bg-fff h-30px radius-6 center">
                                  <img
                                    src={
                                      AppConfig.CDN_Image_url +
                                      "b768514a88b9a901a98ea3bb85c71049.png"
                                    }
                                    className="w-10"
                                  />
                                </div>
                              )}
                              <div className="ps-2">
                                <div className="fs-9px pop-regular text-gray">
                                  Delivery Point Contact
                                </div>
                                <div className="pop-font text-dark fs-12">
                                  {ele.delivery_address.name}
                                </div>
                              </div>
                              <div className="ms-auto end">
                                <div
                                  className="os-icon shadow center"
                                  onClick={(e) => {
                                    let send_data = {
                                      order_id: ele.id,
                                      to: "customer",
                                      brand_id: ele.brand_id,
                                      outlet_id: ele.outlet_id,
                                      // delivery_id: shipment.hyperlocal_pickup.delivery_partner_mobile_delivery_id,
                                    };
                                    InitiateCall(e, send_data);
                                  }}
                                >
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "a78b858bd49bf56c9fa495b641269677.svg"
                                    }
                                    className="w-16px filter-invert"
                                  />
                                </div>
                                <div
                                  className="ms-3 os-icon shadow center filp-icon"
                                  onClick={(e) => {
                                    let send_data = {
                                      order_id: ele.id,
                                      to: "customer",
                                      brand_id: ele.brand_id,
                                      outlet_id: ele.outlet_id,
                                      // delivery_id: shipment.hyperlocal_pickup.delivery_partner_mobile_delivery_id,
                                    };
                                    InitiateChat(e, send_data);
                                  }}
                                >
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                                    }
                                    className="w-16px filter-invert flip-icon"
                                  />
                                </div>
                              </div>
                            </div>

                            {ele?.delivery[0]?.hyperlocal_pickup
                              ?.delivery_partner_name ? (
                              <div>
                                <div className="pt-2 d-flex  py-2">
                                  {/* {tracking_status == "delivery_partner_assigned" ||
                      tracking_status == "order_pickuped" ||
                      tracking_status == "order_delivered" ||
                      tracking_status == "completed" ? ( */}
                                  {ele?.delivery[0]?.hyperlocal_pickup
                                    .delivery_partner_dp ? (
                                    <img
                                      src={
                                        ele?.delivery[0]?.hyperlocal_pickup
                                          .delivery_partner_dp
                                      }
                                      alt="16.a1d4f3f3"
                                      className="rounded-float-start"
                                      height="30"
                                      width="30"
                                      style={{
                                        borderRadius: "6px",
                                      }}
                                    />
                                  ) : (
                                    <div className="w-30px bg-fff h-30px radius-6 center">
                                      <img
                                        src={
                                          AppConfig.CDN_Image_url +
                                          "b768514a88b9a901a98ea3bb85c71049.png"
                                        }
                                        className="w-10"
                                      />
                                    </div>
                                  )}
                                  {/* ) : (
                        <div className="w-30px bg-fff h-30px radius-6 text-center">
                        <img
                          src={
                            AppConfig.CDN_Image_url +
                            "b768514a88b9a901a98ea3bb85c71049.png"
                          }
                          className="w-10"
                        />
                      </div>
                      )} */}
                                  <div className="ps-2">
                                    <div className=" d-flex align-items-center">
                                      <span className="pop-font text-dark fs-12">
                                        {
                                          ele?.delivery[0]?.hyperlocal_pickup
                                            .delivery_partner_name
                                        }

                                        <span
                                          //  className="ps-1 fs-11 pop-regular  text-gray fw-400"

                                          className={
                                            ele.order_status == "Rejected" ||
                                            ele.order_status == "Cancelled"
                                              ? "ps-1 fs-11 pop-regular text-red fw-400 "
                                              : ele.order_status ==
                                                  "Order Completed" ||
                                                ele.order_status == "Completed"
                                              ? "ps-1 fs-11 pop-regular text-green fw-400"
                                              : "ps-1 fs-11 pop-regular text-gray fw-400"
                                          }
                                        >
                                          {
                                            ele?.delivery[0]?.hyperlocal_pickup
                                              .status
                                          }
                                        </span>
                                      </span>
                                    </div>
                                    <div className="fs-10 pop-regular text-gray">
                                      {
                                        ele?.delivery[0]?.hyperlocal_pickup
                                          .company_name
                                      }
                                    </div>
                                  </div>

                                  {ele?.delivery[0]?.hyperlocal_pickup
                                    .delivery_partner_mobile && (
                                    <div className="ms-auto end">
                                      <div
                                        className="os-icon shadow center"
                                        onClick={(e) => {
                                          // del_partner_number
                                          let send_data = {
                                            order_id: ele.id,
                                            chatto: "delivery_partner",
                                            brand_id: ele.brand_id,
                                            outlet_id: ele.outlet_id,
                                            delivery_id:
                                              ele?.delivery[0]
                                                ?.hyperlocal_pickup
                                                .delivery_partner_mobile_delivery_id,
                                          };
                                          InitiateCall(e, send_data);
                                        }}
                                      >
                                        <img
                                          src={
                                            AppConfig.CDN_Media_url +
                                            "a78b858bd49bf56c9fa495b641269677.svg"
                                          }
                                          className="w-16px filter-invert "
                                        />
                                      </div>
                                      <div
                                        className="ms-3 os-icon shadow center "
                                        onClick={(e) => {
                                          let send_data = {
                                            order_id: ele.id,
                                            chatto: "delivery_partner",
                                            brand_id: ele.brand_id,
                                            outlet_id: ele.outlet_id,
                                            delivery_id:
                                              ele?.delivery[0]
                                                ?.hyperlocal_pickup
                                                .delivery_partner_mobile_delivery_id,
                                          };
                                          InitiateChat(e, send_data);
                                        }}
                                      >
                                        <img
                                          src={
                                            AppConfig.CDN_Media_url +
                                            "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                                          }
                                          className="w-16px filter-invert flip-icon"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>

                                {ele?.delivery[0]?.live_tracking == true && (
                                  <div
                                    className="bg-prime1 mt-2 p-2 center radius-6 text-white pop-font fs-12px uppercase let_space_03"
                                    onClick={() => {
                                      console.log("hi");
                                      SetOrderTrackingModal((prevalue: any) => {
                                        prevalue.is_open = true;
                                        prevalue.delivery_index = shipment_i;
                                        prevalue.delivery_id =
                                          ele?.delivery[0]?.id;
                                        prevalue.brand_id = ele.brand_id;
                                        prevalue.outlet_id = ele.outlet_id;
                                        prevalue.order_data = ele;
                                        return {
                                          ...prevalue,
                                        };
                                      });
                                    }}
                                  >
                                    <img
                                      src={
                                        AppConfig.CDN_Media_url +
                                        "1321496623428a5dca385c81ff2124e9.svg"
                                      }
                                      className="w-10 me-2"
                                    />
                                    Track Order
                                  </div>
                                )}
                              </div>
                            ) : (
                              ele.order_status_id != 4 && (
                                <div className="pt-2 d-flex align-items-center">
                                  <div className="w-30px bg-fff h-30px radius-6 center">
                                    <img
                                      src={
                                        AppConfig.CDN_Image_url +
                                        "b768514a88b9a901a98ea3bb85c71049.png"
                                      }
                                      className="w-10"
                                    />
                                  </div>
                                  <div className="ps-1 fs-10 pop-regular text-gray">
                                    We will assign one delivery partner soon
                                  </div>
                                </div>
                              )
                            )}
                          </div>

                          {ele.seller_instructions.length > 0 ? (
                            <div className="mt-2 border radius-6 p-2">
                              <div className="text-dark pop-font fs-12px">
                                Vendor Instruction
                              </div>
                              {ele.seller_instructions.map(
                                (si_ele: any, si_index: any) => (
                                  <div
                                    className={
                                      si_index > 0
                                        ? "fw-500 mt-1 fs-11px text-gray celias-font mt-2"
                                        : "fw-500 mt-1 fs-11px text-gray celias-font"
                                    }
                                  >
                                    {si_ele.message}
                                  </div>
                                )
                              )}
                            </div>
                          ) : null}

                          {ele.order_status_id != 4 ? (
                            <div className="mt-2 w-100 center">
                              {ele.order_status_id == 2 ||
                              (ele.order_status_id == 5 &&
                                ele?.delivery[0]?.status_id == "1") ? (
                                <button
                                  className="btn bg-prime text-white radius h-42px btn-shadow fw-bold px-4 w-75 cursor mt-2"
                                  style={{ overflow: "hidden" }}
                                  onClick={(event) => {
                                    {
                                      ele?.delivery[0]?.package_details
                                        .package_breadth == null
                                        ? SetCreatePackageModal({
                                            is_open: true,
                                            id: ele?.delivery[0]?.id,
                                            data: {
                                              units: data.units,
                                              id: ele?.delivery[0]?.id,
                                              brand_id: ele.brand_id,
                                              outlet_id: ele.outlet_id,
                                            },
                                          })
                                        : submit_ready_ship(
                                            ele?.delivery[0]?.id,
                                            ele.brand_id,
                                            ele.outlet_id
                                          );
                                    }
                                  }}
                                >
                                  PREPARING
                                </button>
                              ) : (
                                ele?.delivery[0]?.status_id == "3" && (
                                  <button
                                    className="btn bg-prime text-white radius btn-shadow fw-500 w-100 cursor fs-14 py-2"
                                    style={{ overflow: "hidden" }}
                                    onClick={(event) => {
                                      {
                                        if (
                                          (ele.sector_id == 2 ||
                                            ele.sector_id == 3 ||
                                            ele.sector_id == 1) &&
                                          ele.delivery_type_id == 3
                                        ) {
                                          submit_ready_ship(
                                            ele?.delivery[0]?.id,
                                            ele.brand_id,
                                            ele.outlet_id
                                          );
                                        } else {
                                          console.log(data);
                                          ele?.delivery[0]?.package_details
                                            .package_breadth == null
                                            ? SetCreatePackageModal({
                                                is_open: true,
                                                id: ele?.delivery[0]?.id,
                                                data: {
                                                  units: data?.units,
                                                  id: ele?.delivery[0]?.id,
                                                  brand_id: ele.brand_id,
                                                  outlet_id: ele.outlet_id,
                                                },
                                              })
                                            : submit_ready_ship(
                                                ele?.delivery[0]?.id,
                                                ele.brand_id,
                                                ele.outlet_id
                                              );
                                        }
                                      }
                                    }}
                                  >
                                    Order Ready
                                  </button>
                                )
                              )}
                            </div>
                          ) : null}

                          {ele?.delivery[0]?.status == "Accepted" && (
                            <div className="mt-2 w-100 center pb-2">
                              <button
                                className="btn bg-prime text-white radius h-42px btn-shadow fw-bold px-3 w-75 cursor mt-2"
                                style={{ overflow: "hidden" }}
                                onClick={(event) => {
                                  SetPrepTimeModal({
                                    is_open: true,
                                    order_id: ele?.delivery[0]?.id,
                                  });
                                }}
                              >
                                PREPARE
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* ))} */}
                    </>
                  ))}
                </>
              </InfiniteScroll>
            ) : (
              <>
                {view_type != "new" ? (
                  <div className="px-2">
                    <div className="card text-center py-5">
                      <img
                        className="img-fluid mx-auto mb-3"
                        width={120}
                        src={
                          AppConfig.CDN_Image_url +
                          "1f99acb3d56f3297ef6ca4f935c7ea5b.png"
                        }
                      />
                      <div>No Orders</div>
                    </div>
                  </div>
                ) : (
                  <div className="px-2">
                    <div className="card text-center py-5">
                      <img
                        className="img-fluid mx-auto mb-3"
                        width={120}
                        src={
                          AppConfig.CDN_Image_url +
                          "34418f066c10584ad5697e993df77b52.png"
                        }
                      />
                      <div>Waiting For New Orders</div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      <OrderChat
        open={chatModal.is_open}
        data={chatModal.data}
        close={(data: any) => {
          SetChatModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            // new_order();
          }
        }}
      />

      <ModalOrderConfirmed
        open={openModal.is_open}
        data={openModal.data}
        close={ModalOnClose}
      />
      <ModalChooseItems
        open={chooseItemModal.is_open}
        data={chooseItemModal.data}
        id={chooseItemModal.id}
        // close={ModalOnClose}
        close={(data) => {
          console.log("close in", data);

          SetChooseItemModal((prevValue) => {
            prevValue.is_open = false;

            console.log("prevValue.is_open ", prevValue);
            return { ...prevValue };
          });
          if (data.action == "edit") {
            get_order(props?.view);
          }
        }}
      />
      <ModalPackageDetails
        open={createPackageModal.is_open}
        data={createPackageModal.data}
        id={chooseItemModal.id}
        // close={ModalOnClose}
        close={(data) => {
          console.log("close in");

          SetCreatePackageModal((prevValue) => {
            prevValue.is_open = false;

            console.log("prevValue.is_open ", prevValue);
            return { ...prevValue };
          });

          if (data.action == "save") {
            get_order(props?.view);
          }
        }}
      />
      <ModalEditPackageDetails
        open={editPackageModal.is_open}
        data={editPackageModal.data}
        id={editPackageModal.id}
        // close={ModalOnClose}
        close={(data) => {
          console.log("close in");

          SetEditPackageModal((prevValue) => {
            prevValue.is_open = false;

            console.log("prevValue.is_open ", prevValue);
            return { ...prevValue };
          });

          if (data.action == "edit") {
            get_order(props?.view);
          }
        }}
      />
      <ModalEditItems
        open={editItemModal.is_open}
        data={editItemModal.data}
        id={editItemModal.id}
        close={(data: any) => {
          SetEditItemModal((prevValue) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });

          if (data.action == "edit") {
            get_order(props?.view);
          }
        }}
      />

      {/* <ModalPrepareTime
        open={prepTimeModal.is_open}
        brand={prepTimeModal.brand}
        outlet={prepTimeModal.outlet}
        order_id={prepTimeModal.order_id}
        approx_time={prepTimeModal.approx_time}
        close={(data: any) => {
          console.log(data);
          SetPrepTimeModal((prevValue) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });

          if (data.action == "close") {
            get_order(props?.view);
          }
        }}
      /> */}

      <ModalDeleteShipment
        open={deleteShipModal.is_open}
        data={deleteShipModal.data}
        close={(data: any) => {
          SetDeleteShipModal({
            is_open: false,
            data: {},
          });

          console.log(data);

          if (data.action == "delete") {
            get_order(props?.view);
          }
        }}
      />
      {/* <ModalLocationLive
        open={mapdriverModal.is_open}
        lat={mapdriverModal.lat}
        lon={mapdriverModal.lon}
        id={mapdriverModal.id}
        // id={deleteModal.id}
        close={(data: any) => {
          // console.log("Franchise modl on close :", data);
          SetMapDriverModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            // get_outlets();
          }
        }}
      /> */}
      {order_data?.length > 0 ? (
        <ModalOrderTracking
          open={order_tracking_modal.is_open}
          delivery_id={order_tracking_modal.delivery_id}
          delivery_index={order_tracking_modal.delivery_index}
          order_details={order_tracking_modal.order_data}
          brand_id={order_tracking_modal.brand_id}
          outlet_id={order_tracking_modal.outlet_id}
          close={() => {
            SetOrderTrackingModal((prevalue: any) => {
              prevalue.is_open = false;
              prevalue.delivery_index = "";
              prevalue.delivery_id = "";
              prevalue.brand_id = "";

              prevalue.outlet_id = "";

              prevalue.order_data = {};
              return { ...prevalue };
            });
          }}
        />
      ) : null}

      {view_type && (
        <ModalRejected
          open={rejectModal.is_open}
          data={rejectModal.data}
          close={(data: any) => {
            SetRejectModal({
              is_open: false,
              data: {},
            });

            console.log(data);

            if (data.action == "delete") {
              get_order(props?.view);
            }
          }}
        />
      )}
    </div>
  );
}
