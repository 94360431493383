import React, { useState, useEffect, useContext, useRef } from "react";
import OrderComponent from "./OrderComponent";
import ModalLocationLive from "./ModalLocationLive";
import ModalRejected from "./ModalRejected";
import ModalChooseItems from "./ModalChooseItems";
import ModalEditPackageDetails from "./ModalEditPackageDetails";
import { api } from "../../../utils/Api";
import ModalPackageDetails from "./ModalPackageDetails";
import { AppHelper } from "../../../utils/Helper";
import { mobileApp } from "../../../utils/MobileApp";
import { useNavigate } from "react-router-dom";

import DataContext from "../../../utils/Context/DataContext";

import { AntDesign, Entypo, Feather } from "@expo/vector-icons";

import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome,
  FontAwesome5,
} from "@expo/vector-icons";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ModalOrderTracking from "./ModalOrderTracking";
import ModalCancelOrder from "./ModalCancelOrder";
import { Dialog } from "@material-ui/core";
import { AppConfig } from "../../../config";
import OrderChat from "../../../components/OrderChat/OrderChat";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function OutletOrderDetails(props: any) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const [counter, setCounter] = useState(0);
  const [view_type, SetViewType] = useState("all");
  const [is_loading, SetIsLoading] = useState(false);

  const anchorRef = React.useRef(null);
  const context: any = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());
  const [response_data, SetResponseData] = useState({});
  const [order_data, SetOrderData]: any = useState([]);
  const [opened_timeline, SetOpenTimeline] = useState([]);
  const [data, SetData] = useState([]);
  const [ack, SetAck] = useState(false);
  const [width, SetWidth] = useState(innerWidth);
  const [mapdriverModal, SetMapDriverModal] = useState({
    is_open: false,
    lat: "",
    lon: "",
    id: "",
  });

  const [order_tracking_modal, SetOrderTrackingModal] = useState({
    is_open: false,
    lat: "",
    lon: "",
    id: "",
    delivery_id: "",
    delivery_index: "",
    brand_id: "",
    outlet_id: "",
  });

  const [rejectModal, SetRejectModal] = useState({
    is_open: false,
    data: {},
  });

  const [chooseItemModal, SetChooseItemModal] = useState({
    is_open: false,
    data: {},
    id: "",
  });

  const [prepTimeModal, SetPrepTimeModal] = useState({
    is_open: false,

    brand: id_value.brand_id,
    outlet: id_value.outlet_id,
    approx_time: "",
    order_id: "",
    data: {},
    index: "",
    max_prep_time: 0,
  });

  const [createPackageModal, SetCreatePackageModal] = useState({
    is_open: false,
    data: {},
    id: "",
  });

  const [editPackageModal, SetEditPackageModal] = useState({
    is_open: false,
    data: {},
    id: "",
  });

  const [cancel_order_modal, SetCancelOrderModal] = useState({
    open: false,
    data: [],
  });

  const [is_cancel_loading, SetIsCancelLoading] = useState(false);
  const [is_timeline_open, SetIsTimelineOpen] = useState(false);

  let order_details_call_interval: any;

  const prevOpen = React.useRef(open);

  const Spinner = () => <div className="loader-spinner"></div>;
  const [chatModal, SetChatModal]: any = useState({
    is_open: false,
    id: "",
    data: {},
  });

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (context.app_data.pages.hasOwnProperty("11")) {
      if (context.app_data.pages[11]) {
        SetIsLoading(true);
        get_order(view_type);
        order_details_call_interval = setInterval(function () {
          // if (order_details.hasOwnProperty("order_status_id")) {
          SetOrderData((prevalue: any) => {
            console.log("order_data in setInterval prevalue:", prevalue);
            if (
              prevalue.length > 0 &&
              prevalue[0].order_status_id != 6 &&
              prevalue[0].order_status_id != 4
            ) {
              console.log("Calling get_order_details");
              get_order(view_type);
            }
            return [...prevalue];
          });

          // }
        }, 20000);
        return () => {
          clearInterval(order_details_call_interval);
        };
      } else {
        console.log("hi");
        navigate("/no_access");
      }
    } else {
      SetIsLoading(true);
    }
  }, [props.view, context.app_data.pages?.[11]]);

  // useEffect(() => {
  //   console.log("useEffect On id_value change  :", id_value);

  //   get_order();
  // }, [id_value]);

  async function get_order(view_type: any) {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: id_value.order_id,
      },
    };

    let response: any = await api("/outlet/order_details", pass_data);

    if (response?.status_code == 200) {
      if (response.response.hasOwnProperty("orders")) {
        let orders = response.response.orders;

        // orders.map((ele: any) => {
        //   ele.open_prepare_data = false;
        // });
        SetResponseData(response?.response);
        console.log(response?.response);
        SetData(response.response.options);
        SetAck(response.response.orders[0].ask_cancelled_ack);
        // SetData(response?.response?.options);
        // SetOrderData(orders);
        SetOrderData((prevalue: any) => {
          const old_value = prevalue;
          console.log("old_value :", old_value);
          orders.map((ele: any, index: any) => {
            if (old_value.length > 0) {
              if (old_value[index].hasOwnProperty("open_prepare_data")) {
                if (old_value[index].id == ele.id) {
                  if (ele.order_status_id == 1) {
                    ele.open_prepare_data = old_value[index].open_prepare_data;
                  } else {
                    ele.open_prepare_data = false;
                  }
                }
              } else {
                console.log("no key");
                ele.open_prepare_data = false;
              }
            } else {
              console.log("length == 0");
              ele.open_prepare_data = false;
            }
          });
          prevalue = orders;
          return [...prevalue];
        });
        SetIsLoading(false);
      }
    }
  }

  async function submitAcknowledge() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: id_value.order_id,
      },
    };

    let response: any = await api(
      "/outlet/order_cancel_acknowledge",
      pass_data
    );

    if (response?.status_code == 200) {
      if (response.response.hasOwnProperty("orders")) {
        // let orders = response.response.orders;
        const ring = {
          function: "refreshringnotifications",
          app_type: "react_web",
        };
        mobileApp(ring);
        // SetOrderData(orders)
        get_order();
      }
    }
  }

  const handleClick1 = (get_data: any) => {
    // Counter state is incremented
    // setCounter(counter + 1);
    SetOrderData((prevalue: any) => {
      let sec_value = prevalue[get_data.index].max_preparation_duration;
      let minutes = Math.floor(sec_value / 60);
      setCounter((prevalue1: any) => {
        if (prevalue1 < minutes) {
          prevalue1 = prevalue1 + 1;
        } else {
          console.log("on high counter prevalue :", prevalue1);
          console.log("on high minutes :", minutes);
        }
        return prevalue1;
      });
      return [...prevalue];
    });
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    // setCounter(counter - 1);
    setCounter((prevalue: any) => {
      if (prevalue > 0) {
        prevalue = prevalue - 1;
      }
      return prevalue;
    });
  };

  async function submit_ready_ship(r_id: any) {
    // props.close();

    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        delivery_id: r_id,
      },
      post: {
        status: "Ready",
      },
    };

    console.log("pass_data :", pass_data);

    let data_res = await api("/outlet/update_delivery_status", pass_data);
    console.log("/outlet/update_delivery_status :", data_res.response);
    if (data_res.status_code == 200) {
      let item = close_data;
      // SetStatus(true);
      // item.action = "edit";
      item.value = data_res.response.orders[0];
      // item.index = props.data.index;
      // console.log("Close data : ", item);
      // SetCloseData(item);
      // SetFranchiseData(franchise_initial_value);
      // myModal.hide();
      // console.log("gfrhjgf")
      // props.close();
    }
  }

  function check_timeline_open(get_data: any) {
    let index_value = opened_timeline.findIndex(
      (ele: any) => ele == get_data.id
    );
    if (index_value != -1) {
      return true;
    } else {
      return false;
    }
  }

  async function accept(order_id: any) {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: order_id,
      },

      post: {
        status: "Accept",
        // outlet : id_value.outlet_id,
        // order_id : order_id,
      },
    };
    console.log("remove_owner record :", order_id);

    let data = await api("/outlet/update_order_status", pass_data);
    console.log("/outlet/update_order_status :", data);
    if (data.status_code == 200) {
      const ring = {
        function: "refreshringnotifications",
        app_type: "react_web",
      };
      mobileApp(ring);
      get_order(view_type);
    }
  }

  async function update_prepare(get_data: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: get_data.id_value,
      },
      post: {
        duration: counter,
        status: "Accept",
      },
    };
    console.log("pass_data :", pass_data);

    let data_res = await api("/outlet/update_order_status", pass_data);
    console.log("/outlet/update_delivery_package :", data_res);

    if (data_res.status_code == 200) {
      // SetPrepTimeModal({
      //   is_open: false,
      //   id: "",
      //   data:{},
      //   index:"",
      // });
      SetOrderData((prevalue1: any) => {
        prevalue1[get_data.index_value].open_prepare_data = false;
        return [...prevalue1];
      });
      SetPrepTimeModal((prevalue: any) => {
        prevalue.is_open = false;
        prevalue.id = "";
        prevalue.data = {};
        prevalue.max_prep_time = 0;
        prevalue.index = "";
        return { ...prevalue };
      });

      get_order(view_type);
    }
  }

  async function del_partner_number(id: any, del_id: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: id,
        callto: del_id != "cus" ? "delivery_partner" : "customer",
        delivery_id: del_id != "cus" ? del_id : "",
      },
    };
    let data_res: any = await api("/outlet/call", pass_data);
    if ((data_res.status_code = 200)) {
      // if (data_res?.response?.phone_number) {
      //   window.open("tel:" + data_res?.response?.phone_number);
      // }
      if (data_res?.response?.phone_number) {
        if (context?.app_data.application?.platform == "android") {
          window.open("tel:" + data_res?.response?.phone_number);
        } else {
          window.location.href = "tel:" + data_res?.response?.phone_number;
        }
      }
    }
  }

  async function cancel_order() {
    console.log("cancel order");
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: id_value.order_id,
      },
      post: {
        status: "Cancel",
      },
    };

    let response: any = await api("/outlet/update_order_status", pass_data);

    if (response?.status_code == 200) {
      if (response.response.hasOwnProperty("orders")) {
        let orders = response.response.orders;

        // orders.map((ele: any) => {
        //   ele.open_prepare_data = false;
        // });
        SetResponseData(response?.response);
        console.log(response?.response);
        SetData(response.response.options);
        SetAck(response.response.orders[0].ask_cancelled_ack);
        // SetData(response?.response?.options);
        // SetOrderData(orders);
        SetOrderData((prevalue: any) => {
          const old_value = prevalue;
          console.log("old_value :", old_value);
          orders.map((ele: any, index: any) => {
            if (old_value.length > 0) {
              if (old_value[index].hasOwnProperty("open_prepare_data")) {
                if (old_value[index].id == ele.id) {
                  if (ele.order_status_id == 1) {
                    ele.open_prepare_data = old_value[index].open_prepare_data;
                  } else {
                    ele.open_prepare_data = false;
                  }
                }
              } else {
                console.log("no key");
                ele.open_prepare_data = false;
              }
            } else {
              console.log("length == 0");
              ele.open_prepare_data = false;
            }
          });
          prevalue = orders;
          return [...prevalue];
        });
      }
    }
  }

  async function get_order_details_for_cancel() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: id_value.order_id,
      },
    };

    let response: any = await api("/outlet/order_details", pass_data);

    if (response?.status_code == 200) {
      if (response.response.hasOwnProperty("orders")) {
        let orders = response.response.orders;
        SetResponseData(response?.response);
        SetOrderData((prevalue: any) => {
          const old_value = prevalue;
          console.log("old_value :", old_value);
          orders.map((ele: any, index: any) => {
            if (old_value.length > 0) {
              if (old_value[index].hasOwnProperty("open_prepare_data")) {
                if (old_value[index].id == ele.id) {
                  if (ele.order_status_id == 1) {
                    ele.open_prepare_data = old_value[index].open_prepare_data;
                  } else {
                    ele.open_prepare_data = false;
                  }
                }
              } else {
                console.log("no key");
                ele.open_prepare_data = false;
              }
            } else {
              console.log("length == 0");
              ele.open_prepare_data = false;
            }
          });
          prevalue = orders;
          SetIsCancelLoading(false);
          SetCancelOrderModal((prevalue1: any) => {
            prevalue1.open = true;
            prevalue1.data = prevalue;
            return { ...prevalue1 };
          });
          return [...prevalue];
        });
      }
    }
  }

  async function chat_pickup(get_data: any) {
    let pass_data: any = {
      get: {
        order_id: get_data.order_id,
        chatto: get_data.chatto,
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
    };
    if (get_data.chatto == "delivery_partner") {
      pass_data.get.delivery_id = get_data.delivery_id;
    }
    let data: any = await api("/outlet/chat", pass_data);
    if (data.status_code == 200) {
      if (data?.response?.sb_user_id && data?.response?.channel_url) {
        SetChatModal({
          is_open: true,
          data: {
            orderId: get_data.order_id,
            sb_user_id: data?.response?.sb_user_id,
            channel_url: data?.response?.channel_url,
            title: data?.response?.user_name,
            profile_url: data?.response?.profile_url,
          },
        });
      }
    }
    // setCallBtnLoader(false);
  }

  return (
    <div className="order_details_page">
      {/* Header */}
      <div
        className="head-bar px-0 "
        style={width < 780 ? { width: width } : {}}
      >
        <div className="d-flex align-items-center mb-1">
          <div
            className="px-3 cursor"
            onClick={() => {
              window.history.go(-1);
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "3760dcf7e8daefc2196a2b9f357fbec1.svg"
              }
              className="w-14px"
            />
            {/* <Ionicons name="arrow-back-outline" size={18} color="black" /> */}
          </div>
          <h6 className=" mb-0">Order Details</h6>
        </div>
      </div>

      <div className="row pb-5">
        {is_loading ? (
          // {/* Skeleton start */}
          <div className="col-md-6 px-sm-0 px-md-2">
            <div className="mb-5 pb-5">
              <div className=" pb-5">
                <div className="mb-2">
                  <div className="px-2 py-3 card mt-2">
                    <div className=" border-bottom p-2 row align-items-center">
                      <div className="col-3 ps-0">
                        <Skeleton height={10} width={80} />
                      </div>
                      <div className="col-9 pe-0 text-end">
                        <Skeleton height={10} width={80} />
                      </div>
                    </div>

                    <div className="bg-fff p-2 pb-3">
                      <div className="pt-2">
                        <div className="d-flex py-1">
                          {/* <img
                              src={require("../../../assets/icon/pin2.png")}
                              className="bank-img"
                            /> */}
                          <Skeleton height={16} width={16} />

                          <div className="px-2">
                            <Skeleton height={10} width={160} />
                            <div className="pt-1">
                              <Skeleton height={8} width={100} />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex  py-1">
                          {/* <img
                          src={require("../../../assets/icon/location-pin1.png")}
                          className="bank-img"
                        /> */}
                          <Skeleton height={14} width={14} />

                          <div className="px-2">
                            <Skeleton height={10} width={160} />

                            <div className="pt-1">
                              <Skeleton height={8} width={190} count={2} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="card px-2 py-3 mt-2 ">
                    <div className="d-flex align-items-center py-2">
                      <Skeleton height={40} width={40} />
                      <div className="px-1 w-100">
                        <div className="d-flex align-items-center pt-1">
                          <Skeleton height={10} width={140} />

                          <div className="d-flex align-items-center ms-auto">
                            <Skeleton height={10} width={10} />
                            <div className="ps-1">
                              <Skeleton height={10} width={80} />
                            </div>
                          </div>
                        </div>

                        <div className=" mt-1">
                          <Skeleton height={8} width={100} />
                        </div>
                      </div>
                    </div>

                    <div className="py-2 order_detail">
                      <Accordion>
                        <AccordionSummary
                          className="my-0 px-1 py-0"
                          aria-controls="panel1d-content"
                        >
                          <div className="row w-100">
                            <div className="col-7 ps-0">
                              <div className="d-flex cursor">
                                <Skeleton height={14} width={14} />

                                <div className="ps-1">
                                  <Skeleton height={10} width={140} />
                                </div>
                              </div>
                            </div>
                            <div className="col-2 py-0 px-1">
                              <Skeleton height={10} width={40} />
                            </div>
                            <div className="col-3 pe-0">
                              <Skeleton height={10} width={100} />
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className=" w-100">
                          <div className=" w-100">
                            <div className="row pb-2 px-0">
                              <div className="col-7 pe-1 ps-0">
                                <Skeleton height={10} width={140} />
                              </div>
                              <div className="col-2">
                                <Skeleton height={10} width={100} />
                              </div>
                              <div className="col-3 p-0">
                                <Skeleton height={10} width={140} />
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="card p-3 mt-2">
                    <div className="mb-3">
                      <Skeleton height={10} width={80} />
                    </div>
                    <div className="pb-2 d-flex align-items-center">
                      <Skeleton height={10} width={120} />
                      <div className="ms-auto">
                        <Skeleton height={10} width={120} />
                      </div>
                    </div>
                    <div className="pb-2 d-flex align-items-center">
                      <Skeleton height={10} width={100} />

                      <div className="ms-auto">
                        <Skeleton height={10} width={50} />
                      </div>
                    </div>
                    <div className="pb-2 d-flex align-items-center">
                      <Skeleton height={10} width={100} />

                      <div className="ms-auto">
                        <Skeleton height={10} width={50} />
                      </div>
                    </div>
                    <div className="pb-2 d-flex align-items-center border-bottom-dot">
                      <Skeleton height={10} width={100} />

                      <div className="ms-auto">
                        <Skeleton height={10} width={50} />
                      </div>
                    </div>
                    <div className="py-2 d-flex align-items-center">
                      <Skeleton height={10} width={100} />

                      <div className="ms-auto">
                        <Skeleton height={10} width={50} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          order_data?.map((ele: any, i_item: any) => (
            <div className="col-md-6 px-sm-0 px-md-2 mb-5" key={i_item}>
              {/* id/ date/ status */}
              <div className="bg-fff shadow-bottom p-2">
                <div className="d-flex align-items-center pb-2 ">
                  <div className="fs-10px text-dark fw-bold pe-2 ">
                    ID: {ele.id}
                  </div>
                  <div className="ms-auto">
                    <span
                      className={
                        ele.order_status == "Cancelled"
                          ? "center badge-1 badge-red ms-2 "
                          : ele.order_status == "Processing"
                          ? "center badge-1 ms-2"
                          : "center badge-1 badge-green ms-2"
                      }
                      //  "center badge-1 ms-2"
                    >
                      {ele.order_status}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="fs-10 text-gray">{ele.time}</div>
                  {ele.dm_order && (
                    <div className="ms-auto d-flex align-items-center text-orange fs-11 uppercase">
                      <div className="orange-circle me-1"></div>
                      Demo
                    </div>
                  )}
                </div>
              </div>

              {/* Customer */}
              <div className="bg-fff p-1 mt-2">
                <div className="bg-light-blue radius-10 d-flex align-items-center">
                  <div className="d-flex align-items-center p-2 w-100">
                    <div className="w-30px bg-fff h-30px radius-6 center">
                      <img
                        src={
                          AppConfig.CDN_Image_url +
                          "b768514a88b9a901a98ea3bb85c71049.png"
                        }
                        className="w-10"
                      />
                    </div>
                    <div className="ps-2">
                      <div className="fs-12px text-dark">
                        {ele.customer_name}
                      </div>
                      <div className="fs-10px text-gray ps-0  lowercase">
                        @{ele.customer_username}
                      </div>
                    </div>
                    {ele.customer_mobile && (
                      <div className="ms-auto p-0 end">
                        <div
                          className="os-icon shadow center cursor "
                          onClick={() => {
                            del_partner_number(ele.id, "cus");
                          }}
                        >
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "a78b858bd49bf56c9fa495b641269677.svg"
                            }
                            className="w-16px filter-invert"
                          />
                        </div>
                        <div
                          className="ms-3 os-icon shadow center cursor "
                          onClick={() => {
                            let send_data = {
                              order_id: ele.id,
                              chatto: "customer",
                            };
                            chat_pickup(send_data);
                          }}
                        >
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                            }
                            className="w-16px filter-invert flip-icon"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Order/Delivery/Payment Details */}
              <div className="py-2">
                <div className="card p-2 pt-3">
                  {/* Order detials */}
                  <fieldset className="bg-fff border radius-10 my-3">
                    <legend className="package-over-text bg-fff">
                      Order Details
                    </legend>
                    <div className="px-2 order-details">
                      {ele.hasOwnProperty("ordered_products") &&
                        ele?.ordered_products?.map((p_ele: any) => (
                          <Accordion>
                            <div className="d-flex w-100 pt-1 border-bottom custom-align-top">
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "b1b871802f6048caa74336bf068322cb.svg"
                                }
                                className="w-10px ms-2 mb-2 cursor"
                              />

                              <div className=" w-100">
                                <div className="ps-2 pb-2 ">
                                  <AccordionSummary
                                    className="my-0 p-0 pt-3"
                                    aria-controls="panel1d-content"
                                  >
                                    <div className="d-flex pb-2 w-100">
                                      <div className="celias-font fs-12 pe-2 w-50 text-dark">
                                        {p_ele.variant_name}
                                      </div>

                                      <div className="px-1 fs-12 text-black-color celias-font w-20per">
                                        × {p_ele.quantity}
                                      </div>

                                      <div className="text-end w-30per ">
                                        <sup className="fs-9px text-gray end celias-font">
                                          {/* <strike>₹ 83.00</strike> */}
                                        </sup>
                                        <div className="text-dark fs-12 celias-font fw-600 mb-1">
                                          {/* ₹ */}
                                          <FontAwesome
                                            name="rupee"
                                            size={10}
                                            color="#000"
                                          />{" "}
                                          {p_ele.amount}
                                        </div>
                                      </div>
                                      <div></div>
                                    </div>
                                  </AccordionSummary>

                                  <AccordionDetails className=" w-100">
                                    <div className="w-100">
                                      {p_ele.product_name == "" ? (
                                        <div className="d-flex pb-2">
                                          <div className="fs-10 text-gray pop-regular w-75">
                                            {p_ele.variant_name}
                                            {"  ( ₹" +
                                              p_ele.price.price_without_gst +
                                              ")"}{" "}
                                          </div>

                                          <div className="px-2 fs-11 text-gray celias-font">
                                            × {p_ele.quantity}
                                          </div>

                                          <div className="ms-auto fs-11 text-gray celias-font">
                                            ₹
                                            {/* <FontAwesome
                                            name="rupee"
                                            size={10}
                                            color="#000"
                                          /> */}{" "}
                                            {
                                              p_ele.price
                                                .total_amount_with_qty_without_gst
                                            }
                                          </div>
                                          <div></div>
                                        </div>
                                      ) : (
                                        <div className="d-flex pb-2">
                                          <div className="fs-11 text-gray  celias-font w-75">
                                            {p_ele.product_name}{" "}
                                            {"  ( ₹" +
                                              p_ele.price.price_without_gst +
                                              ")"}{" "}
                                          </div>

                                          <div className="px-2 fs-11 text-gray celias-font">
                                            × {p_ele.quantity}
                                          </div>

                                          <div className="ms-auto fs-11 text-gray celias-font">
                                            ₹{" "}
                                            {
                                              p_ele.price
                                                .total_amount_with_qty_without_gst
                                            }
                                          </div>
                                          <div></div>
                                        </div>
                                      )}

                                      <div className="d-flex pb-2">
                                        <div className="fs-11 text-gray  celias-font w-75">
                                          GST Amount{" "}
                                          {"%" + p_ele.price.gst_percent} /{" "}
                                          {"  ( ₹" +
                                            p_ele.price.gst_amount +
                                            ")"}{" "}
                                        </div>

                                        <div className="px-2 fs-11 text-gray celias-font">
                                          × {p_ele.quantity}
                                        </div>

                                        <div className="ms-auto fs-11 text-gray celias-font">
                                          ₹
                                          {/* <FontAwesome
                                            name="rupee"
                                            size={10}
                                            color="#000"
                                          /> */}{" "}
                                          {
                                            p_ele.price
                                              .total_gst_amount_with_qty
                                          }
                                        </div>
                                        <div></div>
                                      </div>
                                      {p_ele.addons.length > 0 ? (
                                        <>
                                          <div className="fs-11 text-dark fw-bold mb-1 celias-font">
                                            Addons:
                                          </div>
                                          {p_ele?.addons?.map(
                                            (a_ele: any, a_index: any) => (
                                              <div
                                                className="d-flex pb-2"
                                                key={a_index}
                                              >
                                                <div className="fs-11 text-gray  celias-font w-75">
                                                  {a_ele.name}{" "}
                                                  {"(₹" +
                                                    a_ele.price
                                                      .price_without_gst +
                                                    ")"}{" "}
                                                </div>

                                                <div className="px-2 fs-11 text-gray celias-font">
                                                  × {a_ele.quantity}
                                                </div>

                                                <div className="ms-auto fs-11 text-gray celias-font">
                                                  ₹{" "}
                                                  {
                                                    a_ele.price
                                                      .total_amount_with_qty_without_gst
                                                  }
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </>
                                      ) : null}

                                      <div className="d-flex py-2">
                                        <div className="fs-11 text-gray  celias-font w-75">
                                          Sub Total Addon Amount
                                        </div>
                                        <div className="ms-auto fs-11 text-gray celias-font">
                                          ₹{" "}
                                          {
                                            p_ele.price
                                              .total_addon_amount_without_gst
                                          }
                                        </div>
                                      </div>
                                      <div className="d-flex pb-2">
                                        <div className="fs-11 text-gray  celias-font w-75">
                                          Total Addon GST Amount
                                        </div>
                                        <div className="ms-auto fs-11 text-gray celias-font">
                                          ₹ {p_ele.price.total_addon_gst_amount}
                                        </div>
                                      </div>
                                      <div className="d-flex pb-2">
                                        <div className="fs-11 text-gray  celias-font w-75">
                                          Total Addon Amount
                                        </div>
                                        <div className="ms-auto fs-11 text-gray celias-font">
                                          ₹{" "}
                                          {
                                            p_ele.price
                                              .total_addon_amount_with_gst
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </AccordionDetails>
                                </div>
                              </div>
                            </div>
                          </Accordion>
                        ))}
                    </div>
                  </fieldset>
                  {/* Delivery detals*/}
                  <fieldset className="bg-fff border radius-10 my-3 ">
                    <legend className="package-over-text bg-fff">
                      Delivery Details
                    </legend>

                    <div className="px-2 pt-3">
                      <div className="border-bottom py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Image_url +
                              "2f0620ddc47a95162db3b88a35358317.png"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="fs-12px text-dark">
                              Delivery
                              <span className="fs-12px text-gray px-1 pop-regular">
                                to
                              </span>
                              {ele.delivery_address.type_name}
                            </div>
                            <div className="mt-1 fs-12px pop-regular text-gray">
                              {ele.delivery_address.map_address}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="border-bottom py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Image_url +
                              "b768514a88b9a901a98ea3bb85c71049.png"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="">
                              <div className="fs-10px text-gray ps-0 ">
                                Delivery Contact
                              </div>
                              <div className="fs-12px text-dark">
                                {ele.delivery_address.name}
                              </div>
                            </div>
                          </div>
                          <div className="ms-auto p-0 end">
                            <div
                              className="os-icon shadow center cursor"
                              onClick={() => {
                                del_partner_number(ele.id, "cus");
                              }}
                            >
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "a78b858bd49bf56c9fa495b641269677.svg"
                                }
                                className="w-16px filter-invert "
                              />
                            </div>
                            <div
                              className="ms-3 os-icon shadow center cursor "
                              onClick={() => {
                                let send_data = {
                                  order_id: ele.id,
                                  chatto: "customer",
                                };
                                chat_pickup(send_data);
                              }}
                            >
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                                }
                                className="w-16px filter-invert flip-icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="border-bottom py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "31b719f813a5801b0b9e68653ff6df89.svg"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="">
                              <div className="fs-10px text-gray ps-0 ">
                                Type
                              </div>
                              <div className="fs-12px text-dark">
                                {ele.delivery_type}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  {/* Payment details */}
                  <fieldset className="bg-fff border radius-10 mt-3 mb-2 ">
                    <legend className="package-over-text bg-fff">
                      Payment Details
                    </legend>
                    <div className="px-2 pt-3">
                      <div className="border-bottom py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "df00f48fea32274a961b475f33ac1687.svg"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="">
                              <div className="fs-10px text-gray ps-0 ">
                                Payment Total Amount
                              </div>
                              <div className="fs-12px text-dark">
                                ₹ {ele.total_amount}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="border-bottom py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "0e02dae79946e040eca9d59650d4b0b2.svg"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="">
                              <div className="fs-10px text-gray ps-0 ">
                                Payment Mode
                              </div>
                              <div className="fs-12px text-dark">
                                {ele.payment_type}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "ef0d9b54885475ad627dc6e81a71b8ba.svg"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="">
                              <div className="fs-10px text-gray ps-0 ">
                                Payment Stauts
                              </div>
                              <div className="fs-12px text-dark">
                                {ele.cod_order
                                  ? ele.cod_payment_status == false
                                    ? "Not yet paid"
                                    : "Paid"
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div className="bg-fff p-3 shadow mb-2">
                <div className="d-flex w-100 py-1">
                  <img
                    src={
                      AppConfig.CDN_Media_url +
                      "73967a52c63af7e32d610d91ba54b985.svg"
                    }
                    className="w-16px"
                  />
                  <div className="ps-2">
                    <div className="">
                      <div className="fs-10px text-gray ps-0 ">
                        Vendor Instruction
                      </div>
                      {ele?.seller_instructions?.length > 0 ? (
                        ele?.seller_instructions?.map(
                          (si_ele: any, si_index: any) => (
                            <div
                              key={si_index}
                              className={
                                si_index > 0
                                  ? "fs-12px text-dark mt-2"
                                  : "fs-12px text-dark"
                              }
                            >
                              {si_ele.message}
                            </div>
                          )
                        )
                      ) : (
                        <div className="fs-12px text-dark">No instructions</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {ele.delivery.map((d_ele: any, d_index: any) => (
                <div className="bg-fff shadow p-3 mb-2">
                  <div className="heading mb-4">Shipment {d_index + 1}</div>
                  {/* package content */}
                  <fieldset className="bg-fff border radius-10 mt-3 mb-4">
                    <legend className="package-over-text bg-fff">
                      Package Content
                    </legend>
                    <div className="px-2">
                      <div className="d-flex w-100 pt-1 border-bottom custom-align-top">
                        <img
                          src={
                            AppConfig.CDN_Media_url +
                            "b1b871802f6048caa74336bf068322cb.svg"
                          }
                          className="w-10px ms-2 mb-2 cursor"
                        />

                        {d_ele.products?.map((p_ele: any, p_index: any) => (
                          <div className="pt-3 w-100">
                            <div className="ps-2 pb-2 ">
                              <div className="d-flex pb-2">
                                <div className="celias-font fs-12 pe-2 w-50 text-dark">
                                  {p_ele.variant_name}
                                  {/* Fiery Sausage & Paprika Medium new Hand Tossed */}
                                </div>

                                <div className="px-1 fs-12 text-black-color celias-font w-20per">
                                  × {p_ele.quantity}
                                </div>

                                <div className=" w-30per text-end ">
                                  <sup className="fs-9px text-gray end celias-font">
                                    <strike>₹ {p_ele.mrp_amount_strike}</strike>
                                  </sup>
                                  <div className="text-dark fs-12 celias-font fw-600 mb-1">
                                    ₹ {p_ele.amount}
                                  </div>
                                </div>
                                <div></div>
                              </div>

                              <div className="d-flex pb-2">
                                <div className="fs-10 text-gray  pop-regular">
                                  GST Amount
                                </div>

                                <div className="ms-auto fs-11 text-gray celias-font">
                                  ₹ 7.00
                                </div>
                                <div></div>
                              </div>

                              {p_ele.addons.map((a_ele: any, a_index: any) => (
                                <>
                                  <div className="fs-11 text-dark fw-bold mb-1 celias-font">
                                    Addons:
                                  </div>

                                  <div className="d-flex pb-2">
                                    <div className="fs-11 text-gray  celias-font w-75">
                                      Onion toppings
                                    </div>

                                    <div className="px-2 fs-11 text-gray celias-font">
                                      × 1
                                    </div>

                                    <div className="ms-auto fs-11 text-gray celias-font">
                                      ₹ 10.00
                                    </div>
                                    <div></div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </fieldset>
                  {/* delivery details */}
                  <fieldset className="bg-fff border radius-10 mt-3 mb-4 ">
                    <legend className="package-over-text bg-fff">
                      Delivery Details
                    </legend>
                    <div className="px-2 pt-3">
                      {/* Pickup */}
                      <div className="border-bottom py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Image_url +
                              "69e22d8519c6739a9feceef2d109c256.png"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="fs-12px text-dark">
                              Pickup
                              <span className="fs-12px text-gray px-1 pop-regular">
                                from
                              </span>
                              {ele.outlet_name}
                            </div>
                            <div className="mt-1 fs-12px pop-regular text-gray">
                              {ele.outlet_map_address}
                              {/* 13, Rangan 2nd Street, Karungalpalayam, Pavalatham
                            Palayam, Erode, Tamil Nadu, India, 638003 */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Pickup Contact */}
                      {ele.outlet_contacts.map((oc_ele: any, oc_index: any) => (
                        <div className="border-bottom py-2">
                          <div className="d-flex w-100 py-1">
                            <img
                              src={
                                AppConfig.CDN_Image_url +
                                "b768514a88b9a901a98ea3bb85c71049.png"
                              }
                              className="w-16px"
                            />
                            <div className="ps-2">
                              <div className="">
                                <div className="fs-10px text-gray ps-0 ">
                                  Pickup Contact {oc_index + 1}
                                </div>
                                <div className="fs-12px text-dark">
                                  {oc_ele.id != null
                                    ? oc_ele.name
                                    : oc_ele.full_mobile}
                                </div>
                              </div>
                            </div>
                            <div className="ms-auto p-0 end">
                              <div
                                className="os-icon shadow center cursor"
                                onClick={() => {
                                  console.log("dail pickup contact");
                                  if (
                                    context?.app_data.application?.platform ==
                                    "android"
                                  ) {
                                    window.open("tel:" + oc_ele.full_mobile);
                                  } else {
                                    window.location.href =
                                      "tel:" + oc_ele.full_mobile;
                                  }
                                }}
                              >
                                <img
                                  src={
                                    AppConfig.CDN_Media_url +
                                    "a78b858bd49bf56c9fa495b641269677.svg"
                                  }
                                  className="w-16px filter-invert"
                                />
                              </div>
                              {/* <div className="ms-3 os-icon shadow center cursor ">
                                <img
                                  src={
                                    AppConfig.CDN_Media_url +
                                    "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                                  }
                                  className="w-16px filter-invert flip-icon"
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      ))}

                      {/* Delivery */}
                      <div className="border-bottom py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Image_url +
                              "2f0620ddc47a95162db3b88a35358317.png"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="fs-12px text-dark">
                              Delivery
                              <span className="fs-12px text-gray px-1 pop-regular">
                                to
                              </span>
                              {ele.delivery_address.type_name}
                            </div>
                            <div className="mt-1 fs-12px pop-regular text-gray">
                              {ele.outlet_map_address}
                              {/* 13, Rangan 2nd Street, Karungalpalayam, Pavalatham
                            Palayam, Erode, Tamil Nadu, India, 638003 */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Delivery Contact */}
                      <div className="border-bottom py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Image_url +
                              "b768514a88b9a901a98ea3bb85c71049.png"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="">
                              <div className="fs-10px text-gray ps-0 ">
                                Delivery Contact
                              </div>
                              <div className="fs-12px text-dark">
                                {/* Contact Person */}
                                {ele.delivery_address.name}
                              </div>
                            </div>
                          </div>
                          <div className="ms-auto p-0 end">
                            <div
                              className="os-icon shadow center cursor"
                              onClick={() => {
                                del_partner_number(ele.id, "cus");
                              }}
                            >
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "a78b858bd49bf56c9fa495b641269677.svg"
                                }
                                className="w-16px filter-invert"
                              />
                            </div>
                            <div
                              className="ms-3 os-icon shadow center cursor "
                              onClick={() => {
                                let send_data = {
                                  order_id: ele.id,
                                  chatto: "customer",
                                };
                                chat_pickup(send_data);
                              }}
                            >
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                                }
                                className="w-16px filter-invert flip-icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Type */}
                      <div className="border-bottom py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "31b719f813a5801b0b9e68653ff6df89.svg"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="">
                              <div className="fs-10px text-gray ps-0 ">
                                Type
                              </div>
                              <div className="fs-12px text-dark">
                                {ele.delivery_type}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* delivery company */}
                      {ele.delivery.hasOwnProperty("hyperlocal_pickup") &&
                      ele.delivery.hyperlocal_pickup.hasOwnProperty(
                        "company_name"
                      ) ? (
                        <div className="border-bottom py-2">
                          <div className="d-flex w-100 py-1">
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "72cff535dc607068388db09df26ed166.svg"
                              }
                              className="w-16px"
                            />
                            <div className="ps-2">
                              <div className="">
                                <div className="fs-10px text-gray ps-0 ">
                                  Delivery Company
                                </div>
                                <div className="fs-12px text-dark">
                                  {ele.delivery.hyperlocal_pickup.company_name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {/* delivery instruction */}
                      {/* <div className="border-bottom py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "73967a52c63af7e32d610d91ba54b985.svg"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="">
                              <div className="fs-10px text-gray ps-0 ">
                                Delivery Instruction
                              </div>
                              <div className="fs-12px text-dark">
                                Careful to carry
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* Delivery Executive */}
                      {ele.delivery.hasOwnProperty("hyperlocal_pickup") &&
                      ele.delivery.hyperlocal_pickup.hasOwnProperty(
                        "delivery_partner_name"
                      ) ? (
                        <>
                          <div className="border-bottom py-2">
                            <div className="d-flex w-100 py-1">
                              <img
                                src={
                                  AppConfig.CDN_Image_url +
                                  "b768514a88b9a901a98ea3bb85c71049.png"
                                }
                                className="w-16px"
                              />
                              <div className="ps-2">
                                <div className="">
                                  <div className="fs-10px text-gray ps-0 ">
                                    Delivery Executive
                                  </div>
                                  <div className="fs-12px text-dark">
                                    {/* Contact Person */}
                                    {
                                      ele.delivery.hyperlocal_pickup
                                        .delivery_partner_name
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="ms-auto p-0 end">
                                <div
                                  className="os-icon shadow center cursor "
                                  onClick={() => {
                                    console.log("call deliverry partner");
                                    del_partner_number(ele.id, "deli");
                                  }}
                                >
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "a78b858bd49bf56c9fa495b641269677.svg"
                                    }
                                    className="w-16px filter-invert "
                                  />
                                </div>
                                <div
                                  className="ms-3 os-icon shadow center cursor "
                                  onClick={() => {
                                    let send_data = {
                                      order_id: ele.id,
                                      chatto: "delivery_partner",
                                    };
                                    chat_pickup(send_data);
                                  }}
                                >
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "ce8b7d884ffdb51228e7577cc36f38c0.svg"
                                    }
                                    className="w-16px filter-invert flip-icon"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="py-3">
                            <div className="bg-prime1 p-2 center radius-6 text-white pop-font fs-12px uppercase let_space_03">
                              <img
                                src={
                                  AppConfig.CDN_Media_url +
                                  "1321496623428a5dca385c81ff2124e9.svg"
                                }
                                className="w-10 me-2"
                              />
                              <div className="os-rate text-white ps-1">
                                Track Order
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </fieldset>
                  {/* Payment Details */}
                  <fieldset className="bg-fff border radius-10 mt-3 ">
                    <legend className="package-over-text bg-fff">
                      Payment Details
                    </legend>
                    <div className="px-2 pt-3">
                      <div className="border-bottom py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "df00f48fea32274a961b475f33ac1687.svg"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="">
                              <div className="fs-10px text-gray ps-0 ">
                                Payment Total Amount
                              </div>
                              <div className="fs-12px text-dark">
                                ₹ {ele.total_amount}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="border-bottom py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "0e02dae79946e040eca9d59650d4b0b2.svg"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="">
                              <div className="fs-10px text-gray ps-0 ">
                                Payment Mode
                              </div>
                              <div className="fs-12px text-dark">
                                {ele.payment_type}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="py-2">
                        <div className="d-flex w-100 py-1">
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "ef0d9b54885475ad627dc6e81a71b8ba.svg"
                            }
                            className="w-16px"
                          />
                          <div className="ps-2">
                            <div className="">
                              <div className="fs-10px text-gray ps-0 ">
                                Payment Stauts
                              </div>
                              <div className="fs-12px text-dark">
                                {ele.cod_order
                                  ? ele.cod_payment_status == false
                                    ? "Not yet paid"
                                    : "Paid"
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <div className="border radius-10 mt-3">
                    <div className="d-flex align-items-center p-2">
                      <div className="heading ">Shipment Timeline Log</div>
                      <div
                        className="ms-auto"
                        onClick={() => {
                          SetIsTimelineOpen(!is_timeline_open);
                        }}
                      >
                        {is_timeline_open ? (
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "b1b871802f6048caa74336bf068322cb.svg"
                            }
                            className="w-12px mb-1"
                            style={{ transform: "rotate(-180deg)" }}
                          />
                        ) : (
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "b1b871802f6048caa74336bf068322cb.svg"
                            }
                            className="w-12px mb-1"
                            // style={{ transform: "rotate(90deg)" }}
                          />
                        )}
                      </div>
                    </div>
                    {is_timeline_open ? (
                      <div className="p-3">
                        {ele.delivery.map((d_ele: any, d_index: any) => (
                          <>
                            {d_ele.timeline.map((t_ele: any, t_index: any) => (
                              <div className="od-track-step-timeline">
                                <div className="order-track-status">
                                  <span className="order-track-status-dot completed"></span>
                                  <span className="order-track-status-line completed"></span>
                                </div>
                                <div className="order-track-text ps-1">
                                  <div className=" pb-1 cursor os-dark">
                                    {t_ele.status}
                                    {/* {t_ele.status} */}
                                  </div>
                                  {/* {t_ele.type == "delivery_partner" ? ( */}
                                  <div className="text-gray fs-11 pop-regular pb-1">
                                    {/* {
                            prop_data?.delivery[0].hyperlocal_delivery
                              .delivery_partner_name
                          } */}
                                    {t_ele.type}
                                    {" / "}
                                    {t_ele.time_formatted}
                                  </div>
                                  {/* ) : t_ele.type == "seller" ? (
                        <div className="text-gray fs-11 pop-regular pb-1">
                          {prop_data?.brand_name}
                          {" / "}
                          {t_ele.time_formatted}
                        </div>
                      ) : null} */}
                                </div>
                              </div>
                            ))}
                          </>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}

              <div className="bg-fff shadow p-3 mt-2">
                <div className="pb-2 d-flex align-items-center">
                  <p className="small text-gray pop-regular fs-12">Subtotal</p>
                  <div className="ms-auto">
                    <p className="small text-gray">
                      <FontAwesome name="rupee" size={10} color="#808080" />
                      <span className="pop-font ps-1 pop-regular fs-12">
                        {ele.amount}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="pb-2 d-flex align-items-center">
                  <p className="small pop-regular fs-12">Tax</p>
                  <div className="ms-auto">
                    <p className="small text-gray">
                      <FontAwesome name="rupee" size={10} color="#808080" />
                      <span className="pop-font ps-1 pop-regular fs-12">
                        {ele.gst_amount ? ele.gst_amount : 0}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="pb-2 d-flex align-items-center">
                  <p className="small pop-regular fs-12">Delivery fee</p>
                  <div className="ms-auto">
                    <p className="small text-gray">
                      <FontAwesome name="rupee" size={10} color="#808080" />
                      <span className="pop-font ps-1 pop-regular fs-12">
                        {ele.delivery_charges}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="pb-2 d-flex align-items-center border-bottom-dot">
                  <p className="small pop-regular fs-12">Convenience fee</p>
                  <div className="ms-auto">
                    <p className="small text-gray">
                      <FontAwesome name="rupee" size={10} color="#808080" />
                      <span className="pop-font ps-1 pop-regular fs-12">
                        {ele.convenience_fee == null
                          ? "0"
                          : ele.convenience_fee}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="pt-2 d-flex align-items-center">
                  <p className="heading">Total Amount</p>
                  <div className="ms-auto">
                    <p className="heading">
                      <FontAwesome name="rupee" size={12} color="#000" />
                      <span className="pop-font ps-1">{ele.total_amount}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-fff shadow p-3 mt-2">
                <div className="heading mb-2">Settlement</div>
                <div className="w-100 pt-2">
                  <div className="odsettle container">
                    <div className="order-settle-status ">
                      <div className="d-flex">
                        <span
                          className={
                            ele.settlement_eligible
                              ? "order-settle-status-dot completed"
                              : "order-settle-status-dot"
                          }
                        ></span>
                        <span
                          className={
                            ele.settlement_eligible
                              ? "order-settle-status-line completed"
                              : "order-settle-status-line"
                          }
                        ></span>
                      </div>
                      <div className="pop-reqular fs-12 pt-2 text-darkgray">
                        Settlement Eligible
                      </div>
                    </div>

                    <div className="order-settle-status ">
                      <div className="d-flex">
                        <span
                          className={
                            ele.settlement_approved
                              ? "order-settle-status-dot completed"
                              : "order-settle-status-dot"
                          }
                        ></span>
                        <span
                          className={
                            ele.settlement_approved
                              ? "order-settle-status-line completed"
                              : "order-settle-status-line"
                          }
                        ></span>
                      </div>
                      <div className="pop-reqular fs-12 pt-2 text-darkgray">
                        Settlement Approved
                      </div>
                    </div>

                    <div className="order-settle-status w-auto">
                      <div className="d-flex">
                        <span
                          className={
                            ele.settlement_paid
                              ? "order-settle-status-dot completed"
                              : "order-settle-status-dot"
                          }
                        ></span>
                      </div>
                      <div className="pop-reqular fs-12 pt-2 text-darkgray">
                        Settlement Paid
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-fff shadow p-3 mt-2">
                <div className="heading mb-2">Order Timeline Log</div>
                <div className="px-1">
                  {ele.delivery.map((d_ele: any, d_index: any) => (
                    <>
                      <div className="heading my-3">Shipment {d_index + 1}</div>

                      {d_ele.timeline.map((t_ele: any, t_index: any) => (
                        <div className="od-track-step-timeline">
                          <div className="order-track-status">
                            <span className="order-track-status-dot completed"></span>
                            <span className="order-track-status-line completed"></span>
                          </div>
                          <div className="order-track-text ps-1">
                            <div className=" pb-1 cursor os-dark">
                              {t_ele.status}
                              {/* {t_ele.status} */}
                            </div>
                            {/* {t_ele.type == "delivery_partner" ? ( */}
                            <div className="text-gray fs-11 pop-regular pb-1">
                              {/* {
                            prop_data?.delivery[0].hyperlocal_delivery
                              .delivery_partner_name
                          } */}
                              {t_ele.type}
                              {" / "}
                              {t_ele.time_formatted}
                            </div>
                            {/* ) : t_ele.type == "seller" ? (
                        <div className="text-gray fs-11 pop-regular pb-1">
                          {prop_data?.brand_name}
                          {" / "}
                          {t_ele.time_formatted}
                        </div>
                      ) : null} */}
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </div>
              </div>

              {ele.order_status_id == 1 &&
                (ele.sector_id == 2 ||
                  ele.sector_id == 3 ||
                  ele.sector_id == 1) &&
                ele.delivery_type_id == 3 && (
                  <div className="bottom-fixed-50px md-bottom-fixed shadow d-flex align-items-center">
                    <div
                      className="w-50 center p-3 text-dark uppercase bg-fff"
                      onClick={(event) => {
                        SetRejectModal({
                          is_open: true,
                          data: {
                            id: ele.id,
                            brand_id: ele.brand_id,
                            outlet_id: ele.outlet_id,
                          },
                        });
                      }}
                    >
                      REJECT
                    </div>
                    <div
                      className="w-50 center p-3 text-white uppercase bg-green"
                      onClick={(event) => {
                        accept(ele.id);
                      }}
                    >
                      ACCEPT
                    </div>
                  </div>
                )}
            </div>
          ))
        )}
      </div>

      <OrderChat
        open={chatModal.is_open}
        data={chatModal.data}
        close={(data: any) => {
          SetChatModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            // new_order();
          }
        }}
      />

      <ModalChooseItems
        open={chooseItemModal.is_open}
        data={chooseItemModal.data}
        id={chooseItemModal.id}
        // close={ModalOnClose}
        close={(data) => {
          console.log("close in", data);

          SetChooseItemModal((prevValue) => {
            prevValue.is_open = false;

            console.log("prevValue.is_open ", prevValue);
            return { ...prevValue };
          });
          if (data.action == "edit") {
            get_order(view_type);
          }
        }}
      />

      <ModalPackageDetails
        open={createPackageModal.is_open}
        data={createPackageModal.data}
        id={chooseItemModal.id}
        // close={ModalOnClose}
        close={(data) => {
          console.log("close in");

          SetCreatePackageModal((prevValue) => {
            prevValue.is_open = false;

            console.log("prevValue.is_open ", prevValue);
            return { ...prevValue };
          });

          if (data.action == "save") {
            get_order(view_type);
          }
        }}
      />

      <ModalCancelOrder
        open={cancel_order_modal.open}
        data={cancel_order_modal.data}
        close={(data: any) => {
          console.log("close in", data);

          SetCancelOrderModal((prevValue) => {
            prevValue.open = false;
            return { ...prevValue };
          });

          if (data.action == "save") {
            // get_order(view_type);
            cancel_order();
          }
        }}
      />

      {/* <ModalLocationLive
        open={mapdriverModal.is_open}
        lat={mapdriverModal.lat}
        lon={mapdriverModal.lon}
        id={mapdriverModal.id}
        // id={deleteModal.id}
        close={(data: any) => {
          // console.log("Franchise modl on close :", data);
          SetMapDriverModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            // get_outlets();
          }
        }}
      /> */}

      {order_data.length > 0 ? (
        <ModalOrderTracking
          open={order_tracking_modal.is_open}
          delivery_id={order_tracking_modal.delivery_id}
          delivery_index={order_tracking_modal.delivery_index}
          order_details={order_data[0]}
          brand_id={order_tracking_modal.brand_id}
          outlet_id={order_tracking_modal.outlet_id}
          close={() => {
            SetOrderTrackingModal((prevalue: any) => {
              prevalue.is_open = false;
              prevalue.delivery_index = "";
              prevalue.delivery_id = "";
              return { ...prevalue };
            });
          }}
        />
      ) : null}

      <ModalRejected
        open={rejectModal.is_open}
        data={rejectModal.data}
        close={(data: any) => {
          SetRejectModal({
            is_open: false,
            data: {},
          });

          console.log(data);

          if (data.action == "delete") {
            get_order(view_type);
          }
        }}
      />

      <ModalEditPackageDetails
        open={editPackageModal.is_open}
        data={editPackageModal.data}
        id={editPackageModal.id}
        // close={ModalOnClose}
        close={(data) => {
          console.log("close in");

          SetEditPackageModal((prevValue) => {
            prevValue.is_open = false;

            console.log("prevValue.is_open ", prevValue);
            return { ...prevValue };
          });

          if (data.action == "edit") {
            get_order(view_type);
          }
        }}
      />

      <Dialog
        open={prepTimeModal.is_open}
        onClose={() => {
          // SetPaymentType({
          //   is_open: false,
          //   value: "",
          //   disabled: false,
          //   from: "",
          //   payment_index: "",
          // });
          SetPrepTimeModal({
            is_open: false,
            id: "",
            data: {},
            index: "",
            max_prep_time: 0,
          });
          SetOrderData((prevalue: any) => {
            prevalue[prepTimeModal.index].open_prepare_data = false;
            return [...prevalue];
          });
        }}
        classes={{ container: classes.root, paper: classes.paper }}
      >
        <div className="">
          {/* <div className="px-3 pt-4 pb-2 d-flex align-items-center w-100">
            <div
              className="ms-auto cursor"
              onClick={() => {
                SetPrepTimeModal({
                  is_open: false,
                  id: "",
                  data: {},
                  index: "",
                });
                SetOrderData((prevalue: any) => {
                  prevalue[prepTimeModal.index].open_prepare_data = false;
                  return [...prevalue];
                });
              }}
            >
              <Ionicons name="md-close" size={18} color="black" />
            </div>
          </div> */}

          <div className="">
            <div className="my-2 p-3">
              <div className="text-center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "b68d5cf34d1598c6ca0cdea2de3ed39d.svg"
                  }
                  className="img-fluid mb-3"
                  width={35}
                />
              </div>
              <p className="text-black mb-2 fs-7 small text-center">
                How Long will this take a time to prepare
              </p>
              <div className="center py-2">
                <div className="w-75">
                  <div className="d-flex justify-content-between align-items-center border br-20">
                    <div
                      className="mb-0 vlr cursor"
                      onClick={() => {
                        handleClick2();
                      }}
                    >
                      <AntDesign name="minuscircle" size={28} color="#3f41c3" />
                    </div>

                    <p className="mx-2 mb-0  fw-bold text-dark">
                      {counter} MINS
                    </p>

                    <div
                      className="mb-0 vlr cursor"
                      onClick={() => {
                        let pass_data: any = {
                          index: prepTimeModal.index,
                        };
                        handleClick1(pass_data);
                      }}
                    >
                      <AntDesign name="pluscircle" size={28} color="#3f41c3" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="mt-3 d-flex align-items-center">
                <button
                  className="btn px-3 py-1 text-darkgray bg-fff btn-shadow  btn-radius fw-bold"
                  onClick={() => {
                    SetPrepTimeModal({
                      is_open: false,
                      id: "",
                      data:{},
                      index:"",
                    });
                    SetOrderData((prevalue: any) => {
                      prevalue[i_item].open_prepare_data =
                        false;
                      return [...prevalue];
                    });
                  }}
                >
                  Cancel
                </button>
                <div className="ms-auto">
                  <div
                    className="btn text-prime bg-white px-4 py-1 fw-bold btn-shadow btn-radius"
                    onClick={() => {
                      update_prepare(ele.id);
                    }}
                  >
                    Ok
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-primary br-0 text-uppercase cursor w-100 p-12"
          onClick={() => {
            console.log("Done");
            let send_data: any = {
              id_value: prepTimeModal.data.id,
              index_value: prepTimeModal.index,
            };
            update_prepare(send_data);
          }}
        >
          Done
        </button>
        {/* </div> */}
      </Dialog>
    </div>
  );
}
